import * as EBIC from "@ebic-bbrm/types"
import { ALogQueries } from "./activitylog.api";
import { courseQueries } from "./courses.api";
import useEbicAPI, { EbicMutationOptions, useEbicMutation, useEbicPaginatedQuery } from './useEbicAPI';


export interface FetchOrderOptions {
    query?: string;
    sort?: "Newest" | "Oldest";
    limit?: number;
    orgId?: number;

    active?: boolean;
    inactive?: boolean;
}

export const OrderQueries = {
    ORDERS: (params: FetchOrderOptions) => ['order', 'products', params] as const,

    ORDERSBYID: (orderId: number) => ['order', 'products', orderId] as const,

    ORDERSBYREF: (orderRef: string) => ['order', 'products', orderRef] as const

}

export const ordersBaseRoute = '/api/order'

export function useAllOrders(params: FetchOrderOptions = {}, enabled = true) {
    const { limit = 15, ...rest } = params;

    const options = {
        limit, ...rest
    }

    const sortParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof params]
        if (value !== undefined) {
            sortParams.set(key, String(value))
        }
    }

    return useEbicPaginatedQuery<EBIC.Order.Order[]>(
        OrderQueries.ORDERS(params),
        `/api/order?${sortParams.toString()}`,
        true,
        { enabled }
    )
}

export function useOrderById(orderId: number, enabled = true) {
    return useEbicAPI<EBIC.Order.Order>(
        OrderQueries.ORDERSBYID(orderId),
        `${ordersBaseRoute}/${orderId}`,
        true,
        { enabled }
    );
}

export function useOrderByRef(orderRef: string, enabled = true) {
    return useEbicAPI<EBIC.Order.Order>(
        OrderQueries.ORDERSBYREF(orderRef),
        `${ordersBaseRoute}/orderRef`,
        true,
        { enabled }
    );
}

export function initaliseOrderObject(order: EBIC.Order.Order) {
    const requiredFields = [
        'order_id',
        'order_ref',
        'org_id',
        'prod_id',
        'order_by',
        'order_date',
        'order_expiry'
    ];
    const originalOrder: EBIC.Order.Order =
        requiredFields.reduce(
            (accumulator, currentValue) => {
                return {
                    ...accumulator,
                    ...{
                        [currentValue]: order[currentValue as keyof typeof order],
                    },
                };
            },
            {} as EBIC.Order.Order
        );
    return originalOrder;
}

interface TCreateOrder {
    order_ref: string;
    org_id: number;
    prod_id: number;
    order_by: number;
    order_expiry: string | null;
}

type EditOrderForm = Pick<
    EBIC.Order.Order,
    | 'order_id'
    | 'order_ref'
    | 'prod_id'
    | 'order_by'
    | 'order_expiry'
>

const OrderMutations = {
    CREATE: ({ org_id, prod_id, ...order }: TCreateOrder): EbicMutationOptions => ({
        url: `${ordersBaseRoute}`,
        method: 'POST',
        requestBody: { org_id, prod_id, ...order },
        updater: [
            {
                queryKey: OrderQueries.ORDERS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: courseQueries.COURSES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]

    }),
    EDIT: ({ order_id, ...order }: EditOrderForm): EbicMutationOptions => ({
        url: `${ordersBaseRoute}/${order_id}`,
        method: 'PUT',
        requestBody: order,
        updater: [
            {
                queryKey: OrderQueries.ORDERSBYID(order_id),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: OrderQueries.ORDERS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: courseQueries.COURSES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]

    }),
    DELETE: ({ order_id }: { order_id: number }): EbicMutationOptions => ({
        url: `${ordersBaseRoute}/${order_id}`,
        method: 'DELETE',
        updater: [
            {

                queryKey: OrderQueries.ORDERSBYID(order_id),
                partialMatch: true,
                action: 'refetch'

            },
            {
                queryKey: OrderQueries.ORDERS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: courseQueries.COURSES({}),
                partialMatch: true,
                action: 'refetch'
            },

        ]
    })

}

export function useOrderMutations<Key extends keyof typeof OrderMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = OrderMutations[key] as (
        params: Parameters<typeof OrderMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}
