import {
  StopCircle,
  Pending,
  PauseCircle,
  PlayCircle,
} from "@mui/icons-material";
import { useMemo, useState, useEffect, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  FetchOrganizationOptions,
  useAllOrganizations,
} from "../../../api/organization.api";
import useQuery from "../../../hooks/useQuery";
import ToggleButtonGroup, { ToggleItem } from "../../ui/ToggleButtonGroup";
import SearchBar from "../../ui/SearchBar";
import EmptyMessage from "../../utils/EmptyMessage";
import OrgItem from "./OrgItem";
import { Pagination } from "@mui/material";

export default function AllOrgList({
  emptyMessage,
}: {
  emptyMessage?: string;
}) {
  const search = useQuery();
  const history = useHistory();

  const params = useMemo(() => {
    const params: FetchOrganizationOptions = {};

    //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.
    const query = decodeURIComponent(search.get("q") || "");

    if (query) params.query = query;

    const filterKey =
      FILTER_MAP[decodeURIComponent(search.get("f") || "") as FilterKeys];
    //if null, no filter (all posts)
    if (filterKey !== null) {
      //set matching filter key to true;

      //if undefined, default to show `active` but only if not searching
      if (filterKey !== undefined || !query) {
        params[filterKey ?? "active"] = true;
      }
    }
    return params;
  }, [search]);

  const {
    data: allOrg,
    fetchNextPage,
    refetch,
  } = useAllOrganizations(params);

  const handleChange = (event: any, value: number) => {
    search.set("page", value.toString());
    history.replace({
      search: search.toString(),
    });
    //document.documentElement.scrollTop = 0
    fetchNextPage({ pageParam: value });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getCurrentPage = useCallback(
    (page: number) => {
      return allOrg?.pages.find((p) => p.page === page);
    },
    [allOrg]
  );

  const tab = useQuery().get("t");

  const page = parseInt(search.get("page") ?? "1");
  useEffect(() => {
    if (!getCurrentPage(page) && tab === "c") {
      //check if next page exists
      fetchNextPage({ pageParam: page });
    }
  }, [getCurrentPage, page, fetchNextPage, tab]);

  const organizations = getCurrentPage(page)?.result;

  //Special case: refetch when navigating to active page
  useEffect(() => {
    if (params.active) {
      refetch();
    }
  }, [params.active, refetch]);

  // const handleFetchNextSpinnerMount = useCallback(
  //   (node: HTMLDivElement | null) => {
  //     if (!node) return;

  //     const options = {
  //       root: null,
  //       rootMargin: "0px",
  //       threshold: 0,
  //     };

  //     const observer = new IntersectionObserver((entries) => {
  //       if (entries[0]?.isIntersecting === true && !isLoading && hasNextPage) {
  //         fetchNextPage();
  //       }
  //     }, options);

  //     observer.observe(node);
  //   },
  //   [isLoading, hasNextPage, fetchNextPage]
  // );

  //Search
  const [query, setquery] = useState<string>("");

  const handleEnterPressed = () => {
    if (query !== "") {
      search.set("q", query);
      history.replace({
        search: search.toString(),
      });
    } else {
      history.replace("/allOrganizations");
    }
  };
  //end of search

  let content: JSX.Element;

  if (organizations === undefined) {
    content = <OrganizationSkeleton />;
  } else if (organizations.length === 0) {
    content = (
      <>
        <EmptyMessage
          className="fs-5"
          message={
            emptyMessage ??
            "There are no Institutions created yet. 尚未创建任何机构。"
          }
        />
      </>
    );
  } else {
    content = (
      <>
        <Row className="gy-3 gy-lg-4" xs="1" lg="5">
          {organizations.map((o) => (
            <Col key={o.org_id} style={{ minHeight: "27rem" }}>
              <OrgItem organization={o} />
            </Col>
          ))}
          </Row>
          <Row className="mt-3">
             {allOrg?.pages !== undefined && allOrg?.pages[0]?.total > 10 && (
                <div className="d-flex justify-content-center my-3">
                  <Pagination
                    count={Math.ceil(
                      allOrg?.pages[0]?.total / allOrg?.pages[0].limit
                    )}
                    page={page}
                    onChange={handleChange}
                  />
                </div>
              )}
          </Row>
      </>
    );
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <AllOrgHeader />

        <SearchBar
          query={query}
          handleEnterPressed={handleEnterPressed}
          setquery={setquery}
        />
      </div>

      {content}
    </div>
  );
}
type KeysWithType<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];
//Maps for item keys in URL search
type FilterKeys = "p" | "a" | "s" | "t";
/**
 * If undefined, we select `following_only` by default.
 * If null, we select public posts (no filter).
 */
const FILTER_MAP: Record<
  FilterKeys,
  KeysWithType<Required<FetchOrganizationOptions>, boolean> | null
> = {
  p: "pending",
  a: "active",
  s: "suspended",
  t: "terminated",
} as const;

function AllOrgHeader() {
  const search = useQuery();
  const history = useHistory();

  const handleFilterChange = (filter: FilterKeys) => {
    search.set("f", filter);
    history.replace({
      search: search.toString(),
    });
  };

  type Item = Omit<ToggleItem, "active" | "render">;

  let activeFilter = decodeURIComponent(search.get("f") || "") as FilterKeys;
  if (!(activeFilter in FILTER_MAP)) activeFilter = "a";

  const filterItems: Record<FilterKeys, Item> = {
    a: {
      Icon: PlayCircle,
      label: "Active 在线",
    },
    p: {
      Icon: Pending,
      label: "Pending 待办",
    },
    s: {
      Icon: PauseCircle,
      label: "Suspended 暂停",
    },
    t: {
      Icon: StopCircle,
      label: "Terminated 终止",
    },
  };

  return (
    <div className="mb-2 d-flex align-items-center justify-content-between flex-wrap">
      <ToggleButtonGroup
        items={Object.entries(filterItems).map(([key, item]) => ({
          ...item,
          key: key as FilterKeys,
          active: key === activeFilter,
        }))}
        onSelect={(item) => {
          handleFilterChange(item.key);
        }}
      />
    </div>
  );
}

export function OrganizationSkeleton() {
  return (
    <>
      {Array<null>(3)
        .fill(null)
        .map((_, idx) => (
          <div
            key={idx}
            className="shadow-sm rounded-4 mb-2 d-flex justify-content-between align-items-center bg-light p-3"
          >
            <div
              className="rounded-circle skeleton-box me-2"
              style={{ width: 50, height: 50 }}
            ></div>
            <div className="flex-grow-1 align-self-stretch py-1 d-flex flex-column justify-content-between">
              <div className="skeleton-box" style={{ width: "60%" }}></div>
              <div
                className="skeleton-box"
                style={{ width: "30%", height: 14 }}
              ></div>
            </div>
          </div>
        ))}
    </>
  );
}
